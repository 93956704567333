const CREATED = 'created';
const UPDATED = 'updated';
const DELETED = 'deleted';
const REFUNDED = 'refunded';
const ADD_TICKETS = 'addTickets';
const ADD_FREE_TICKET = 'addFreeTicket';
const NOTIFY_START = 'notifyStart';
const START = 'start';
const ROUND = 'round';
const WINNER = 'winner';
const CLAIMED = 'claimed';
const END = 'end';

export const DRAWING_BOARD_EVENTS = {
  CREATED,
  UPDATED,
  DELETED,
  REFUNDED,
  ADD_TICKETS,
  ADD_FREE_TICKET,
  NOTIFY_START,
  START,
  ROUND,
  WINNER,
  CLAIMED,
  END
};

export const SUBSCRIPTION_BOARD_UPDATES_EVENTS = [
  ADD_TICKETS,
  ADD_FREE_TICKET,
  WINNER,
  REFUNDED,
  CLAIMED
];
