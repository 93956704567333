import { createSelector } from 'reselect';
import { fromIso9075DateString } from 'utils/dates';
import { DRAWING_BOARD_EVENTS } from './drawingConstants';

//  ----------------------------------------------------------------------------------------------//
//  actions
export const ADD_DRAWING_EVENT = 'Drawings/ADD_DRAWING_EVENT';
export const ADD_DRAWING_EVENTS = 'Drawings/ADD_DRAWING_EVENTS';
export const UPDATE_LAST_DRAWING_EVENT = 'Drawings/UPDATE_LAST_DRAWING_EVENT';
export function addDrawingEvent(event) {
  return {
    type: ADD_DRAWING_EVENT,
    payload: { event }
  };
}

export function addDrawingEvents(events) {
  return {
    type: ADD_DRAWING_EVENTS,
    payload: { events }
  };
}

export function updateLastDrawingEvent(data) {
  return {
    type: UPDATE_LAST_DRAWING_EVENT,
    payload: { data }
  };
}

//  ----------------------------------------------------------------------------------------------//
//  selectors

const selectDrawingEvents = state => state.get('drawingEvents');

export const selectEvents = createSelector(
  selectDrawingEvents,
  // events => events.get('events')
  events => events.events
);

export const selectLastEvent = createSelector(
  selectDrawingEvents,
  events => events.last
);

//  ----------------------------------------------------------------------------------------------//
//  reducer
const initialState = { events: [], last: null };
const MAX_EVENTS_LENGTH = 30;

const augmentEvent = event => {
  const e = { ...event };
  e.date = fromIso9075DateString(e.date);
  e.user = { ...e.user };
  e.prizes = { ...e.prizes };
  e.prizes.prizes = [...e.prizes.prizes];
  e.processed = false;
  return e;
};

const prependEvent = (events, event) => {
  let toAdd = false;
  let same = false;
  if (event.event === DRAWING_BOARD_EVENTS.ADD_TICKETS) {
    same = events.find(
      e =>
        e.drawingId === event.drawingId &&
        e.event === event.event &&
        e.user.userId === event.user.userId
    );
    if (same) {
      const dt = fromIso9075DateString(event.date);
      if (same.date.getTime() < dt.getTime()) {
        same.date = dt;
        same.tickets = event.tickets;
      }
    } else {
      toAdd = true;
    }
  } else if (
    event.event === DRAWING_BOARD_EVENTS.CLAIMED ||
    event.event === DRAWING_BOARD_EVENTS.ADD_FREE_TICKET
  ) {
    toAdd = true;
  }
  if (toAdd) {
    return [augmentEvent(event)].concat(events);
  }
  return events;
};

export default function drawingEventsReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_DRAWING_EVENT: {
      const events = prependEvent(state.events, action.payload.event);
      if (events.length > MAX_EVENTS_LENGTH) {
        events.length = MAX_EVENTS_LENGTH;
      }
      return Object.assign({}, state, { events });
    }

    case ADD_DRAWING_EVENTS: {
      if (
        !action.payload.events ||
        !action.payload.events.length ||
        state.events.length /*allows only 1st add*/
      ) {
        return state;
      }
      const events = action.payload.events.reduce(
        (combined, event) => prependEvent(combined, event),
        [...state.events]
      );
      if (events.length > MAX_EVENTS_LENGTH) {
        events.length = MAX_EVENTS_LENGTH;
      }

      return Object.assign({}, state, { events });
    }

    case UPDATE_LAST_DRAWING_EVENT: {
      if (
        action.payload.data.event.event === DRAWING_BOARD_EVENTS.NOTIFY_START ||
        action.payload.data.event.event === DRAWING_BOARD_EVENTS.ADD_TICKETS ||
        action.payload.data.event.event === DRAWING_BOARD_EVENTS.ADD_FREE_TICKET
      ) {
        return state;
      }
      action.payload.data.event = augmentEvent(action.payload.data.event);
      return Object.assign({}, state, {
        last: action.payload.data
      });
    }

    default:
      return state;
  }
}
